<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.data.label')"
              @click="loadAttributes"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    :label="$t('form.parent_id.label')"
                    label-for="parent_id"
                    :state="errors && errors.parent_id ? false : null"
                  >
                    <treeselect
                      id="parent_id"
                      v-model="item.parent_id"
                      :multiple="false"
                      :options="categoryOptions"
                      :normalizer="normalizer"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.parent_id">
                      {{ errors.parent_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          ref="previewEl"
                          rounded
                          :src="$options.filters.mediaUrl(item, 'avatar', '110x110')"
                          height="80"
                        />
                      </b-link>
                      <!--/ avatar -->

                      <!-- reset -->
                      <b-button
                        v-if="item.avatar"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="item.avatar = null"
                      >
                        {{ $t('general.reset') }}
                      </b-button>
                      <!--/ reset -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <InitFileManager
                        field="avatar"
                        type="category_avatar"
                        :multiple="false"
                        @fm-selected="selectedAvatar"
                      />

                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>

                    </b-media-body>
                  </b-media>
                  <!--/ media -->
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.related_attribute_id.label')"
                    label-for="related_attribute_id"
                  >
                    <v-select
                      id="related_attribute_id"
                      v-model="item.related_attribute_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="attributesOptions"
                      :reduce="val => val.value"
                      input-id="related_attribute_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.related_attribute_id">
                      {{ errors.related_attribute_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.buy_with_attribute_id.label')"
                    label-for="buy_with_attribute_id"
                  >
                    <v-select
                      id="buy_with_attribute_id"
                      v-model="item.buy_with_attribute_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="attributesOptions"
                      :reduce="val => val.value"
                      input-id="buy_with_attribute_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.buy_with_attribute_id">
                      {{ errors.buy_with_attribute_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.related_this_category.label')"
                    label-for="related_this_category"
                  >
                    <b-form-checkbox
                      id="related_this_category"
                      v-model="item.related_this_category"
                      input-id="related_this_category"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.related_this_category">
                      {{ errors.related_this_category[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.buy_with_this_category.label')"
                    label-for="buy_with_this_category"
                  >
                    <b-form-checkbox
                      id="buy_with_this_category"
                      v-model="item.buy_with_this_category"
                      input-id="buy_with_this_category"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.buy_with_this_category">
                      {{ errors.buy_with_this_category[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.navigation.extensions')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.top_extension_id.label')"
                    label-for="top_extension_id"
                    :state="errors && errors.top_extension_id ? false : null"
                  >
                    <v-select
                      id="top_extension_id"
                      v-model="item.top_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="top_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.top_extension_id">
                      {{ errors.top_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.bottom_extension_id.label')"
                    label-for="bottom_extension_id"
                    :state="errors && errors.bottom_extension_id ? false : null"
                  >
                    <v-select
                      id="bottom_extension_id"
                      v-model="item.bottom_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="bottom_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.bottom_extension_id">
                      {{ errors.bottom_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.attributes.label')"
              @click="loadAttributes"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(attribute, index) in item.attributes"
                  :id="'attribute' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'attributes.' + index + '.attribute_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_id'"
                        v-model="attribute.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="attributesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_id'"
                        @input="loadAttributeValue($event, index)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                        {{ errors['attributes.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.attribute_value_id.label')"
                      :label-for="'attributes.' + index + '.attribute_value_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_value_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_value_id'"
                        v-model="attribute.attribute_value_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :disabled="!attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                        :reduce="val => val.value"
                        :clearable="true"
                        :input-id="'attributes.' + index + '.attribute_value_id'"
                        :taggable="true"
                        :create-option="val => ({ value: `new_${val}`, label: val })"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_value_id']">
                        {{ errors['attributes.' + index + '.attribute_value_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.position.label')"
                      :label-for="'attributes.' + index + '.position'"
                    >
                      <b-form-input
                        :id="'attributes.' + index + '.position'"
                        v-model="attribute.position"
                        :state="errors && errors['attributes.' + index + '.position'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.position']">
                        {{ errors['attributes.' + index + '.' + '.position'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.can_indexing.label')"
                      :label-for="'attributes.' + index + '.can_indexing'"
                    >
                      <b-form-checkbox
                        :id="'attributes.' + index + '.can_indexing'"
                        v-model="attribute.can_indexing"
                        :state="errors && errors['attributes.' + index + '.can_indexing'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.can_indexing']">
                        {{ errors['attributes.' + index + '.' + '.can_indexing'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.hide_empty.label')"
                      :label-for="'attributes.' + index + '.hide_empty'"
                    >
                      <b-form-checkbox
                        :id="'attributes.' + index + '.hide_empty'"
                        v-model="attribute.hide_empty"
                        :state="errors && errors['attributes.' + index + '.hide_empty'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.hide_empty']">
                        {{ errors['attributes.' + index + '.' + '.hide_empty'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeAttribute(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.attributes"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.categories.tabs.tags.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(tag, index) in item.tags"
                      :id="'tag' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'tags.' + index + '.title.' + language.code"
                        >
                          <b-form-input
                            :id="'tags.' + index + '.title.' + language.code"
                            v-model="tag.title[language.code]"
                            :state="errors && errors['tags.' + index + '.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['tags.' + index + '.title.' + language.code]">
                            {{ errors['tags.' + index + '.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.href.label')"
                          :label-for="'tags.' + index + '.href.' + language.code"
                        >
                          <b-form-input
                            :id="'tags.' + index + '.href.' + language.code"
                            v-model="tag.href[language.code]"
                            :state="errors && errors['tags.' + index + '.href.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['tags.' + index + '.href.' + language.code]">
                            {{ errors['tags.' + index + '.href.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeTag(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addTag"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.tags"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.tags[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.extensions.tabs.faq.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(faqItem, index) in item.faq"
                      :id="'faq' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.question.label')"
                          :label-for="'faq.' + index + '.question.' + language.code"
                        >
                          <b-form-input
                            :id="'faq.' + index + '.question.' + language.code"
                            v-model="faqItem.question[language.code]"
                            :state="errors && errors['faq.' + index + '.question.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.question.' + language.code]">
                            {{ errors['faq.' + index + '.question.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.answer.label')"
                          :label-for="'answer.' + language.code"
                          :state="errors && errors['answer.' + language.code] ? false : null"
                        >
                          <quill-editor
                            :id="'answer.' + language.code"
                            v-model="faqItem.answer[language.code]"
                            :options="editorOptions"
                            :state="errors && errors['answer.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['answer.' + language.code]">
                            {{ errors['answer.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeFaq(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addFaq"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.faq"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.faq[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'product-categories-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
  BImg, BMedia, BMediaBody, BMediaAside, BLink, BFormFile, BFormTextarea, BOverlay,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import InitFileManager from '@/components/InitFileManager'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BImg,
    BFormFile,
    BFormTextarea,
    quillEditor,
    Treeselect,
    BOverlay,
    InitFileManager,
  },
  mixins: [CategoryMixin, SweetAlertMixin, StatusMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      categoryOptions: [],
      extensionOptions: [],
      attributesOptions: [],
      attributeValuesOptions: [],
      loading: {
        attributes: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/administration/categories', {
      params: {
        type: 'product',
      },
    })
      .then(response => {
        this.categoryOptions = response.data
      })

    await this.$http.get(`/api/administration/categories/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        if (!_.isNull(data.topExtension) && !_.find(this.extensionOptions, { value: data.topExtension.id })) {
          this.extensionOptions.push({
            value: data.topExtension.id,
            label: data.topExtension.title,
          })
        }

        if (!_.isNull(data.bottomExtension) && !_.find(this.extensionOptions, { value: data.bottomExtension.id })) {
          this.extensionOptions.push({
            value: data.bottomExtension.id,
            label: data.bottomExtension.title,
          })
        }

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'product-categories-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    loadAttributes() {
      if (Object.keys(this.attributesOptions).length) {
        return
      }

      this.loading.attributes = false

      this.$http.get('/api/attributes', { params: { status: 'enabled', per_page: 100, relations: 'values.translations' } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

          const vm = this

          _.each(response.data.data, item => {
            vm.attributeValuesOptions[`attribute_${item.id}`] = vm.$options.filters.transformForVSelect(item.values, 'id', 'title')
          })

          this.loading.attributes = true
        })
        .catch(() => {
          this.loading.attributes = true
        })
    },
    async loadAttributeValue(id, index) {
      this.item.attributes[index].attribute_value_id = null

      if (this.attributeValuesOptions && _.has(this.attributeValuesOptions, `attribute_${id}`)) {
        return
      }

      const response = await this.$http.get(`/api/attributes/${id}/values`)

      this.$set(this.attributeValuesOptions, `attribute_${id}`, await this.$options.filters.transformForVSelect(response.data.data, 'id', 'title'))
    },
    async searchExtension(query) {
      await this.$http.get('/api/administration/extensions', {
        params: {
          query,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    selectedAvatar(data) {
      this.item.avatar = data
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: null,
        attribute_value_id: null,
        position: Object.keys(this.item.attributes).length + 1,
        can_indexing: false,
        hide_empty: false,
      })
      this.attributeValuesOptions.push([])
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
      this.attributeValuesOptions.splice(index, 1)
    },
    addTag() {
      const data = {
        title: {},
        href: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.href[language.code] = null
      })

      this.item.tags.push(data)
    },
    removeTag(index) {
      this.item.tags.splice(index, 1)
    },
    addFaq() {
      const data = {
        question: {},
        answer: {},
      }

      _.each(this.languages, language => {
        data.question[language.code] = null
        data.answer[language.code] = null
      })

      this.item.faq.push(data)
    },
    removeFaq(index) {
      this.item.faq.splice(index, 1)
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        type: 'product',
        parent_id: this.item.parent_id,
        avatar: this.item.avatar,
        status: this.item.status,
        indexing_status: this.item.indexing_status,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        top_extension_id: this.item.top_extension_id,
        bottom_extension_id: this.item.bottom_extension_id,
        related_attribute_id: this.item.related_attribute_id,
        buy_with_attribute_id: this.item.buy_with_attribute_id,
        related_this_category: this.item.related_this_category,
        buy_with_this_category: this.item.buy_with_this_category,
        attributes: this.item.attributes,
        tags: this.item.tags,
        faq: this.item.faq,
      }

      this.$http.put(`/api/administration/categories/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'product-categories-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        top_extension_id: item.top_extension_id,
        bottom_extension_id: item.bottom_extension_id,
        related_attribute_id: item.related_attribute_id,
        buy_with_attribute_id: item.buy_with_attribute_id,
        related_this_category: item.related_this_category,
        buy_with_this_category: item.buy_with_this_category,
        avatar: this.$options.filters.singleMedia(item.avatar),
        parent_id: item.parent_id,
        status: item.status,
        indexing_status: item.indexing_status,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        attributes: [],
        tags: [],
        faq: [],
      }

      _.each(item.tags, (brand, index) => {
        data.tags[index] = {
          id: brand.id,
          title: {},
          href: {},
        }

        _.each(this.languages, language => {
          const translation = _.find(brand.translations, { locale: language.code })

          data.tags[index].title[language.code] = translation ? translation.title : null
          data.tags[index].href[language.code] = translation ? translation.href : null
        })
      })

      _.each(item.faq, (faq, index) => {
        data.faq[index] = {
          id: faq.id,
          question: {},
          answer: {},
        }

        _.each(this.languages, language => {
          const translation = _.find(faq.translations, { locale: language.code })

          data.faq[index].question[language.code] = translation ? translation.question : null
          data.faq[index].answer[language.code] = translation ? translation.answer : null
        })
      })

      if (!_.isNull(item.topExtension)) {
        data.top_extension_id = item.topExtension.id
      }

      if (!_.isNull(item.bottomExtension)) {
        data.bottom_extension_id = item.bottomExtension.id
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      data.attributes = _.reduce(item.attributes, (result, attribute) => {
        result.push({
          id: attribute.id,
          attribute_id: attribute.attribute_id,
          attribute_value_id: attribute.attribute_value_id,
          position: attribute.position,
          can_indexing: attribute.can_indexing,
          hide_empty: attribute.hide_empty,
        })
        return result
      }, [])

      return data
    },
  },
}
</script>
